import React from "react";
import { Helmet } from "react-helmet";
const SEO = ({
  title = "VMARKLAB - Platform Grosir Fashion Online",
  description = "VMARKLAB adalah platform grosir fashion online yang melayani pesanan minimal satu seri atau custom order dengan pengiriman ke seluruh Indonesia.",
  keywords = "VMARKLAB, grosir fashion, grosir pakaian, fashion online, custom order, grosir JABODETABEK, toko fashion online",
  author = "VMARKLAB",
  ogTitle = null,
  ogDescription = null,
  ogImage = "/logoVmarkLab.svg",
  ogUrl = null,
  twitterCard = "summary_large_image",
}) => {
  const hostname = window.location.origin;
  const fullOgUrl = ogUrl ? `${hostname}/${ogUrl}` : hostname;
  
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content={author} />
      <meta property="og:title" content={ogTitle || title} />
      <meta property="og:description" content={ogDescription || description} />
      <meta property="og:image" content={hostname+ogImage} />
      <meta property="og:url" content={fullOgUrl} />
      <meta name="twitter:card" content={twitterCard} />
    </Helmet>
  );
};

export default SEO;
