import React, { useMemo, useEffect, Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import SEO from "./utils/Seo";

const RoutesConfig = React.lazy(() => import("./routes"));

function App() {
  const theme = useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: "Poppins, Arial, sans-serif",
        },
      }),
    []
  );

  useEffect(() => {
    // Set scroll behavior
    window.history.scrollRestoration = "manual";
    window.scrollTo({ top: 0, behavior: "smooth" });

    // Initialize Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "G-XZE672VL04");
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <SEO />
      <CssBaseline />
      <Router>
        <Suspense fallback={null}>
          <RoutesConfig />
        </Suspense>
      </Router>
    </ThemeProvider>
  );
}

export default App;
